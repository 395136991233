<template>
    <div class="mainSpyContainer">
        <Yon></Yon>
    </div>
</template>

<script>
    import Yon from './Yon';


    export default {
        components: {
            Yon
        }
    }
</script>



